import {
    SHARED_SCHOOL_YEAR,
    SHARED_GRADES_TYPES,
    SHARED_ROLES,
    SHARED_CLASSES,
    NEXT_YEAR_SHARED_CLASSES,
    SHARED_CLASSES_ALL,
    SHARED_GRADES,
    SHARED_ALL_GRADES,
    SHARED_DOCUMENTS_TYPE,
    SHARED_COUNTRY,
    SHARED_RELIGION,
    SHARED_GRADUATION_TYPE,
    SHARED_PASSPORT_TYPE,
    SHARED_SUBJECTS_LIST,
    SHARED_DEGREE_TYPE,
    SHARED_EXPERIENCE_TYPE,
    SHARED_CONTRACT_PERIOD,
    SHARED_BANK_LIST,
    SHARED_ABSENTREASON_LIST,
    SHARED_GET_FEESTYPE, FEES_LIST_FOR_STUDENT, RESET_FEES_LIST_FOR_STUDENT,
    GET_PERCENTAGE_LIST, GET_BROTHERPERCENTAGE_LIST,
    SHARED_GET_EMPLOYEE_LIST, SHARED_GET_MASTER_FEES,
    SHARED_GET_SCHOOL_SECTIONS_LIST,
    SHARED_GET_SCHOOL_DEPARTMENTS_LIST,
    SHARED_GET_SCHOOL_TEACHERS_LIST,
    SHARED_GET_SCHOOL_SUBJECTS_LIST,
    SHARED_GET_SCHOOL_SUBJECTS_LIST_ALL,
    SHARED_GET_SCHOOL_PERIODICALS_LIST,
    SHARED_ALL_CLASSES,
    SHARED_GET_SCHOOL_SUBJECTS_LIST_BY_TEACHER,
    STUDENT_TYPES,
    SHARED_NEXT_YEAR_GRADES,
    GET_CLASSES_WITH_COUNT,
    GET_DAMAGED_BOOKS,
    GET_NEXT_YEAR_SUBJECTS_LIST,
    GET_PREVIOUSE_GRADES,
    UPDATE_INTERNET_SPEED,
    GET_SHARED_REASONS,
    GET_FEES,
    GET_NEXT_YEAR_FEES,
    GET_SHARED_ABSENCE_PERIODICAL,
    GET_SUPERVIOR_TEACHERS
} from '../constants/SharedData';
const initStateShared = {
    schoolYear: [],
    activeYear: '',
    gradesTypes: [],
    roles: [],
    grades: [],
    nextYeargrades: [],
    gradesAll: [],
    classes: [],
    classesAll: [],
    allClasses: [],
    documentTypes: [],
    countryList: [],

    religionList: [],
    graduationTypeList: [],
    passportTypeList: [],
    subjectsList: [],
    degreeList: [],
    experienceList: [],
    contractPeriodList: [],
    bankList: [],
    absentReasonList: [],
    // Fees Type
    sharedFeesTypeList: [],
    feesListingForStudent: [],
    percentageList: [],
    brotherPercentageList: [],
    sharedEmployeeList: [],
    sharedMasterFeesList: [],

    sharedShcoolSections: [],
    sharedShcoolDepartments: [],
    sharedSchoolTeachers: [],
    sharedSchoolSubjects: [],
    sharedSchoolSubjectsAll: [],
    sharedSchoolPeriodicals: [],
    sharedSchoolSubjectsByTeacher: [],
    studentTypes: [],
    classesWithStuedntCount: [],
    damagedBooks:[],
    nextYearclasses:[],
    nextYearSubjectsList:[],
    previousGrades:[],
    interNetSpeed:'',
    sharedReasons:[],
    fees:[],
    nextYearFees:[],
    absencePeriodicals: {},
    supervisorTeachers: []
};

const Shared = (state = initStateShared, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHARED_SCHOOL_YEAR:
            const schoolYear= payload?.data || [];
            const activeYear= payload?.data?.find((yearItem) => yearItem?.status === 1)?.year || "";
            const activeYearId= payload?.data?.find((yearItem) => yearItem?.status === 1)?.id || "";
            const nextAcademicYear = activeYear?.split('-')?.map(el => Number(el) + 1)?.join('-') || '';
            const prevAcademicYear = activeYear?.split('-')?.map(el => Number(el) - 1)?.join('-') || '';
            const nextAcademicYearId = schoolYear?.find(el => el?.year === nextAcademicYear)?.id || '';
            const prevAcademicYearId = schoolYear?.find(el => el?.year === prevAcademicYear)?.id || '';
            return {
                ...state,
                schoolYear,
                activeYear,
                activeYearId,
                nextAcademicYear,
                nextAcademicYearId,
                prevAcademicYear,
                prevAcademicYearId
            };
        case SHARED_GRADES_TYPES:
            return {
                ...state,
                gradesTypes: payload?.data || []
            };
        case SHARED_ROLES:
            return {
                ...state,
                roles: payload?.data || []
            };
        case SHARED_GRADES:
            return {
                ...state,
                grades: payload?.data || []
            };
        case GET_PREVIOUSE_GRADES:
            return {
                ...state,
                previousGrades: payload?.data || []
            };
            
        case SHARED_NEXT_YEAR_GRADES:
            return {
                ...state,
                nextYeargrades: payload?.data || []
            };
        case SHARED_ALL_GRADES:
            return {
                ...state,
                gradesAll: payload?.data || []
            };
        case SHARED_CLASSES:
            return {
                ...state,
                classes: payload?.data || []
            };
        case NEXT_YEAR_SHARED_CLASSES:
            return {
                ...state,
                nextYearclasses: payload?.data || []
            };
        case SHARED_CLASSES_ALL:
            return {
                ...state,
                classesAll: payload?.data || []
            };
        case SHARED_ALL_CLASSES:
            return {
                ...state,
                allClasses: payload?.data || []
            };
        case SHARED_DOCUMENTS_TYPE:
            return {
                ...state,
                documentTypes: payload?.data || []
            };

        case SHARED_COUNTRY:
            return {
                ...state, countryList: payload?.data || []
            };


        case SHARED_RELIGION:
            return {
                ...state, religionList: payload?.data || []
            };
        case SHARED_GRADUATION_TYPE:
            return {
                ...state, graduationTypeList: payload?.data || []
            };
        case SHARED_PASSPORT_TYPE:
            return {
                ...state, passportTypeList: payload?.data || []
            };
        case SHARED_SUBJECTS_LIST:
            return {
                ...state, subjectsList: payload?.data || []
            };
        case GET_NEXT_YEAR_SUBJECTS_LIST:
            return {
                ...state, nextYearSubjectsList: payload?.data || []
            };
            
        case SHARED_DEGREE_TYPE:
            return {
                ...state, degreeList: payload?.data || []
            };
        case SHARED_EXPERIENCE_TYPE:
            return {
                ...state, experienceList: payload?.data || []
            };
        case SHARED_CONTRACT_PERIOD:
            return {
                ...state, contractPeriodList: payload?.data || []
            };
        case SHARED_BANK_LIST:
            return {
                ...state, bankList: payload?.data || []
            };

        case SHARED_ABSENTREASON_LIST:
            return {
                ...state, absentReasonList: payload?.data || []
            };

        case SHARED_GET_FEESTYPE:
            return {
                ...state, sharedFeesTypeList: payload?.data || []
            };
        case FEES_LIST_FOR_STUDENT:
            return {
                ...state, feesListingForStudent: payload?.data || []
            };

        case RESET_FEES_LIST_FOR_STUDENT:
            return {
                ...state, feesListingForStudent: []
            };
        case GET_PERCENTAGE_LIST:
            return {
                ...state, percentageList: payload?.data || []
            };
        case GET_BROTHERPERCENTAGE_LIST:
            return {
                ...state, brotherPercentageList: payload?.data || []
            };
        case SHARED_GET_EMPLOYEE_LIST:
            return {
                ...state, sharedEmployeeList: payload?.data || []
            };
        case SHARED_GET_MASTER_FEES:
            return {
                ...state, sharedMasterFeesList: payload?.data || []
            };
        case SHARED_GET_SCHOOL_SECTIONS_LIST:
            return {
                ...state, sharedShcoolSections: payload?.data || []
            };
        case SHARED_GET_SCHOOL_DEPARTMENTS_LIST:
            return {
                ...state, sharedShcoolDepartments: payload?.data || []
            };
        case SHARED_GET_SCHOOL_TEACHERS_LIST:
            return {
                ...state, sharedSchoolTeachers: payload?.data || []
            };
        case SHARED_GET_SCHOOL_SUBJECTS_LIST:
            return {
                ...state, sharedSchoolSubjects: payload?.data || []
            };
        case SHARED_GET_SCHOOL_SUBJECTS_LIST_ALL:
            return {
                ...state, sharedSchoolSubjectsAll: payload?.data || []
            };
        case SHARED_GET_SCHOOL_PERIODICALS_LIST:
            return {
                ...state, sharedSchoolPeriodicals: payload?.data || []
            };
        case SHARED_GET_SCHOOL_SUBJECTS_LIST_BY_TEACHER:
            return {
                ...state, sharedSchoolSubjectsByTeacher: payload?.data || []
            };
        case STUDENT_TYPES:
            return {
                ...state, studentTypes: payload?.data || []
            };
        case GET_CLASSES_WITH_COUNT:
            return {
                ...state, classesWithStuedntCount: payload?.data || []
            };
        case GET_DAMAGED_BOOKS:           
            return{
                ...state,
                damagedBooks: payload?.data
            }
        case UPDATE_INTERNET_SPEED:
            return {
                ...state, interNetSpeed: payload || ''
            };
        case GET_SHARED_REASONS:
            return {
                ...state,
                sharedReasons: payload?.data || ''
            };
        case GET_FEES:
            return {
                ...state,
                fees: payload?.data || ''
            };
        case GET_NEXT_YEAR_FEES:
            return {
                ...state,
                nextYearFees: payload?.data || ''
            };
        case GET_SHARED_ABSENCE_PERIODICAL:
            return {
                ...state,
                absencePeriodicals: payload?.data || ''
            };
        case GET_SUPERVIOR_TEACHERS:
            return {
                ...state,
                supervisorTeachers: payload?.data || ''
            };
        
            
        default:
            return state;
    }
};

export default Shared;